import { Link } from 'gatsby';
import styled from 'styled-components';
import ColorTransform from 'color';
import { Color, getColor } from '../../../theme/color';
import { BLOCK_LAYOUT, CONTAINER, getMediaQuery, getSpacerAsPixels, getUnitAsPixels, getVerticalSpacingAsStyle, GRID, GRID_LAYOUT, ScreenSize, VerticalSpacingType, VerticalSpacingVariant } from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';


export const BodyContent = styled.ol`
  ${GRID_LAYOUT}
  margin-bottom: ${getSpacerAsPixels({multiplier:-1})};
  counter-reset: item;
`;

export const Item = styled.li`
  margin-bottom: ${getSpacerAsPixels()};
  grid-column: span 4;
  display: flex;

  &:before{
    counter-increment: item;
    content: counter(item) ".";
    ${getTypography(Type.HEADING_2)}
    font-size: 46px;
    color: ${({ theme }) => ColorTransform(theme.foreground.primary).alpha(0.2)};
    width: ${getUnitAsPixels({multiplier:7})};
    text-align: center;
    flex: 0 0 auto;
  }

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    grid-column: span 12;
    gap: ${getUnitAsPixels({multiplier:2})};
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    grid-column: span 4;
  }
`;

export const Heading = styled.h2`
  ${getTypography(Type.HEADING_2)}
  color: ${({ theme }) => theme.foreground.primary};
  display: inline-block;
  position: relative;
  padding-top: ${getUnitAsPixels()};
  background: linear-gradient(transparent 50%, ${({ theme }) => theme.foreground.highlight} 50%);

  &:after{
    content: ".";
    color: ${({ theme }) => theme.background.primary};
  }
`;

export const Description = styled.div`
  ${getTypography(Type.BODY_LARGE)}
  color: ${({ theme }) => theme.foreground.primary};
  margin-top: ${getUnitAsPixels({multiplier:1})};
  
  >p {
    margin-bottom: ${getUnitAsPixels({multiplier:1})};
    
    ${getMediaQuery(ScreenSize.LARGE)}{
      margin-bottom: ${getUnitAsPixels({multiplier:2})};
    }

    &:last-child{
      margin-bottom: 0;
    }
  }
`

export const HeadingLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.foreground.primary};
  display: inline-block;
`;

export const HeaderContainer = styled.div`
  ${CONTAINER}
  ${getVerticalSpacingAsStyle(VerticalSpacingType.PADDING,VerticalSpacingVariant.LARGE,{bottom:false})}
  ${getVerticalSpacingAsStyle(VerticalSpacingType.PADDING,VerticalSpacingVariant.SMALL,{top:false})}
`

export const HeaderContent = styled.div`
  ${BLOCK_LAYOUT}
`

export const BodyContainer = styled.div`
  ${CONTAINER}
  ${getVerticalSpacingAsStyle(VerticalSpacingType.PADDING,VerticalSpacingVariant.LARGE,{top:false})}
`

export const Container = styled.section<{borderTop?:boolean,borderBottom?:boolean}>`
  background-color: ${({ theme }) => theme.background.primary === getColor(Color.WHITE) ? "transparent" : theme.background.primary};
  border-top: ${({theme, borderTop}) => borderTop ? `1px solid ${theme.background.secondary}` : `none` };
  border-bottom: ${({theme, borderBottom}) => borderBottom ? `1px solid ${theme.background.secondary}` : `none` };
`;

export const Button = styled.button`
  ${getTypography(Type.HEADING_4)}
  cursor: pointer;
  color: ${({ theme }) => theme.foreground.primary};
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.background.secondary};
  ${getVerticalSpacingAsStyle(VerticalSpacingType.MARGIN,VerticalSpacingVariant.SMALL,{bottom:false})}
`