import { ImageDataLike } from "gatsby-plugin-image";
import { get, has, set } from "lodash";

const prop = (image:ImageDataLike,path:string,id:string) => {
  if(has(image,path)){
    const prop = get(image,path);
    if(prop.indexOf("?id=") < 0){
      set(image,path,get(image,path).replaceAll('?',`?id=${id}&`))
    }
  }
}

export const uniqueImagePath = (image?:ImageDataLike,id?:string) => {
  if(image && id){
    prop(image,"images.fallback.src",id);
    prop(image,"images.fallback.srcSet",id);
  }
};