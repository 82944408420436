import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { get, sortBy } from 'lodash';
import { SEO } from '../components/common/seo';
import { getSchema } from '../common/schema';
import { parseArticles, parseBodySections, parseStories } from '../common/content';
import { SectionBody } from '../components/body/section';
import { ArticleSectionLayout } from '../components/article/section/section.types';
import { getTheme, Theme } from '../theme/color';
import { ArticleSection } from '../components/article/section';
import { StorySection } from '../components/story/section';
import { getInternalURL } from '../common/link';
import { useLocale } from '../context/locale';
import { Sections } from '../components/layout/sections';
import { useDictionary } from '../context/dictionary';
import { HeadingSize, HeadingVariant } from '../components/common/heading/heading.types';


export const query = graphql`
  query HomeQuery($id: String!, $locale: String!, $topics: [String]!, $categories: [String]!, $sections: [String]!) {
    settings: site {
      siteMetadata {
        siteUrl
      }
    }
    site: datoCmsSite {
      ...SiteInformation
    }
    page: datoCmsPageHome(
      id: { eq: $id }
    ) {
      model {
        apiKey
      }
      meta {
        ...PageMeta
      }
      seo {
        ...PageSEO
      }
      locale
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }

      featured {
        ...ArticleCard
      }
      body {
        ... on DatoCmsTopic {
          model {
            apiKey
          }
          locale
          slug
          id
          originalId
          name
        }
        ... on DatoCmsCategory {
          model {
            apiKey
          }
          locale
          slug
          id
          originalId
          name
          theme
        }
        ... on DatoCmsSection {
          model {
            apiKey
          }
          locale
          slug
          id
          originalId
          name
        }
      }
    }
    versions: allDatoCmsPageHome{
      nodes {
        locale
        model {
          apiKey
        }
      }
    }
    articlesByTopic: allDatoCmsArticle(
      filter: {slug: {ne: null}, topics: {elemMatch: {id: {in: $topics}}}},
      sort: {fields: meta___firstPublishedAt, order: DESC}
    ) {
      group(
        field: topics___originalId,
        limit: 3
      ) {
        nodes {
          ...ArticleCard
        }
        originalId:fieldValue
      }
    }
    articlesByCategory: allDatoCmsArticle(
      filter: {slug: {ne: null}, category: {id: {in: $categories}}},
      sort: {fields: meta___firstPublishedAt, order: DESC}
    ) {
      group(
        field: category___originalId,
        limit: 10
      ) {
        nodes {
          ...ArticleCard
        }
        originalId:fieldValue
      }
    }
    articlesBySection: allDatoCmsArticle(
      filter: {slug: {ne: null}, section: {id: {in: $sections}}},
      sort: {fields: meta___firstPublishedAt, order: DESC}
    ) {
      group(
        field: section___originalId,
        limit: 3
      ) {
        nodes {
          ...ArticleCard
        }
        originalId:fieldValue
      }
    }
    latest: datoCmsPageLatest(
      locale: { eq: $locale }
    ) {
      title
      description
    }
    updates: allDatoCmsUpdate(
      filter: {locale: {eq: $locale}, dateString: {ne: null}},
      sort: {fields: dateString, order: DESC},
      limit : 1
    ) {
      nodes {
        stories {
          ...StoryCard
        }
        
      }
    }
  }
`;

const Home = ({ data, pageContext }: any) => {
  const settings = get(data, 'settings.siteMetadata');
  const page = get(data, 'page');
  const site = get(data, 'site');
  
  const versions = get(data, 'versions.nodes');

  const articlesByTopic = get(data, 'articlesByTopic.group');
  const articlesByCategory = get(data, 'articlesByCategory.group');
  const articlesBySection = get(data, 'articlesBySection.group');
  const latestPage = get(data, 'latest');
  const latestUpdate = get(data, 'updates.nodes[0]');

  if (!page || !settings || !site) return null;

  const schema = getSchema({ settings, site, page, pageContext });

  const locale = useLocale();

  const headingFeatured = useDictionary("headingFeatured");

  const featured = {
    heading: headingFeatured,
    headingVariant: HeadingVariant.OVERLINE,
    headingSize: HeadingSize.LARGE,
    articles: parseArticles(get(page,'featured',[]).filter(article => article.slug)),
    layout: ArticleSectionLayout.FEATURED,
    theme: Theme.OFF_WHITE,
  }

  const update = {
    heading: latestPage.title,
    description: latestPage.description,
    link: getInternalURL({pageType:"page_latest",locale}),
    stories: parseStories(get(latestUpdate,'stories',[])),
  }

  const body = parseBodySections(page.body,{articlesByTopic,articlesByCategory,articlesBySection});

  return (
    <Fragment>
      <SEO 
        tags={page.seoMetaTags}
        favicon={site.faviconMetaTags}
        siteURL={settings.siteUrl}
        schema={schema}
        isArticle={false}
        page={page}
        versions={versions}
      />
      <ArticleSection {...featured} />
      <StorySection {...update} theme={getTheme(Theme.WHITE)} headingVariant={HeadingVariant.UNDERLINE} headingSize={HeadingSize.LARGE} />
      <Sections content={body} defaultTheme={Theme.OFF_WHITE} />
    </Fragment>
  );
};

export default Home;