import { Link } from "gatsby";
import styled from "styled-components";
import { getTextHover } from "../../../theme/animation";
import { getUnitAsPixels } from "../../../theme/layout";
import { getTypography, Type } from "../../../theme/typography";
import ColorTransform from "color";

export const Container = styled.div`
  flex: 1;
`

export const Publication = styled.a`
  display: inline-block;
  margin-bottom: ${getUnitAsPixels()};
  height: ${getUnitAsPixels({multiplier:3})};
  display: flex;
  align-items: flex-end;
`

export const Heading = styled.a`
  ${getTypography(Type.HEADING_4)}
  color: ${({ theme }) => theme.foreground.primary};
  display: block;
`

export const ArrowIcon = styled.svg`
  margin-inline-start: ${getUnitAsPixels()};
  stroke: ${({theme}) => theme.foreground.tertiary};
  transition: stroke 0.3s;
`

export const Action = styled.a`
  ${getTypography(Type.HEADING_5)}
  ${({theme}) => getTextHover(theme.foreground.tertiary)}
  display: block;
  margin-top: ${getUnitAsPixels()};

  &:hover{
    ${ArrowIcon}{
      stroke: ${({theme}) => ColorTransform(theme.foreground.tertiary).alpha(0.7)};
    }
  }
`;

