import React from 'react';
import { ThemeProvider } from 'styled-components';
import { plainTextToHTML } from '../../../common/string';
import { getTheme, Theme } from '../../../theme/color';
import { Heading } from '../../common/heading';
import { StoryCard } from '../card';

import { Container, Item, HeaderContainer, HeaderContent, BodyContainer, BodyContent, Button, Description } from './section.styles';
import { StorySectionProps } from './section.types';

export const StorySection = ({heading,headingSize,headingVariant,description,link,stories,buttonLabel,onButton,theme,borderTop,borderBottom}:StorySectionProps) => {
  return (
    <ThemeProvider theme={theme ? getTheme(theme) : getTheme(Theme.WHITE)}>
      <Container borderTop={borderTop} borderBottom={borderBottom}>
        <HeaderContainer>
          <HeaderContent>
            <Heading size={headingSize} variant={headingVariant} link={link}>{heading}</Heading>
            {description && <Description>{plainTextToHTML(description)}</Description>}
          </HeaderContent>
        </HeaderContainer>
        <BodyContainer>
          <BodyContent>
            {stories.map((story,index) => (
              <Item key={index}><StoryCard {...story} /></Item>
            ))}
            {buttonLabel && <Button onClick={onButton}>{buttonLabel}</Button>}
          </BodyContent>
        </BodyContainer>
      </Container>
    </ThemeProvider>
  )
};