import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useEffect, useRef } from 'react';
import { ArticleCardProps } from '.';
import { uniqueImagePath } from '../../../common/image';

import { Action, Container, Content, Description, Heading, Media, MediaDuration, MediaIcon, MetaItem, MetaItems, MetaLink, Thumbnail } from './card.styles';

export const ArticleCard = ({groupId,id,heading,description,thumbnail,topic,category,action,link,vertical,featured,mediaType,duration}:ArticleCardProps) => {
  const image = thumbnail ? thumbnail.gatsbyImageData || {
    images:{
      fallback: {
        srcSet:thumbnail.responsiveImage.srcSet,
        src:thumbnail.responsiveImage.src,
        sizes:thumbnail.responsiveImage.sizes,
      }
    },
    layout: "fullWidth",
    width: thumbnail.responsiveImage.width,
    height: thumbnail.responsiveImage.height,
  } : undefined;

  uniqueImagePath(image,groupId ? groupId + "-" + id : id);
  
  return (
    <Container vertical={vertical} featured={featured}>
      <Thumbnail to={link}>
        {image && <GatsbyImage image={image} alt={thumbnail.alt || heading} loading="lazy" />}
        {(mediaType === "media_video" || mediaType === "media_audio") && 
          <Media>
            {mediaType === "media_video" && <MediaIcon width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.33301 2.49512L12.6663 8.49512L3.33301 14.4951V2.49512Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
            </MediaIcon>}
            {mediaType === "media_audio" && <MediaIcon width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.3335 3.99313L4.00016 6.6598H1.3335V10.6598H4.00016L7.3335 13.3265V3.99313Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M12.7137 3.94647C13.9635 5.19666 14.6656 6.89204 14.6656 8.65981C14.6656 10.4276 13.9635 12.123 12.7137 13.3731M10.3604 6.29981C10.9853 6.9249 11.3363 7.77259 11.3363 8.65647C11.3363 9.54035 10.9853 10.388 10.3604 11.0131" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
            </MediaIcon>}
            <MediaDuration>{duration}</MediaDuration>
          </Media>
        }
      </Thumbnail>
      <Content>
        <MetaItems>
          {category && <MetaItem><MetaLink to={category.link}>{category.heading}</MetaLink></MetaItem>}
          {topic && <MetaItem><MetaLink to={topic.link}>{topic.heading}</MetaLink></MetaItem>}
        </MetaItems>
        <Heading to={link}>{heading}</Heading>
        {featured && <Description>{description}</Description>}
        <Action to={link}>{action}</Action>
      </Content>
    </Container>
  )
};