import { Link } from 'gatsby';
import styled from 'styled-components';
import ColorTransform from 'color';
import { getGutterAsPixels, getMediaQuery, getSpacerAsPixels, getUnitAsPixels, ScreenSize } from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';
import { Color, getColor } from '../../../theme/color';
import { getTextHover } from "../../../theme/animation";

export const Heading = styled(Link)`
  color: ${({ theme }) => theme.foreground.secondary};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const Description = styled.p`
  ${getTypography(Type.BODY_REGULAR)}
  margin-top: ${getUnitAsPixels()};
  color: ${({ theme }) => theme.foreground.secondary};
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const MetaItems = styled.ul`
  margin-bottom: ${getUnitAsPixels()};
  color: ${({ theme }) => ColorTransform(theme.foreground.secondary).alpha(0.7)};
`

export const MetaItem = styled.li`
  ${getTypography(Type.LABEL)}
  display: inline-block;
  
  &:after{
    content: "•";
    margin: 0 10px;
  }

  &:last-child{
    &:after{
      content: none;
    }
  }
`

export const MetaLink = styled(Link)`
  text-decoration: none;
  ${({theme}) => getTextHover(theme.foreground.secondary,0.7)}
`;

export const Thumbnail = styled(Link)`
  flex: 1;
  position: relative;
  border: 1px solid ${({theme}) => ColorTransform(theme.foreground.secondary).alpha(0.1).toString()};
`

export const Media = styled.span`
  ${getTypography(Type.LABEL)}  
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: ${getColor(Color.WHITE)};
  color: ${getColor(Color.BLACK)};
  padding: ${getUnitAsPixels({multiplier:1})} ${getUnitAsPixels({multiplier:2})};
  display: flex;
`

export const MediaDuration = styled.span`
  margin-inline-start: ${getUnitAsPixels({multiplier:0.5})};
`

export const MediaIcon = styled.svg`
  
`

export const Content = styled.div`
  flex: 1;
`;

export const Action = styled(Link)`
  ${getTypography(Type.HEADING_5)}
  ${({theme}) => getTextHover(theme.foreground.tertiary)}
  display: inline-block;
  margin-top: ${getUnitAsPixels()};
`;

export const Container = styled.div<{vertical:boolean,featured:boolean}>`
  display: flex;
  flex-direction: ${({vertical}) => vertical ? `column` : `row`};
  align-items: ${({vertical}) => vertical ? `stretch` : `flex-start`};
  
  ${Heading}{
    ${getTypography(Type.HEADING_4)}

    ${getMediaQuery(ScreenSize.LARGE)}{
      ${({featured}) => featured ? getTypography(Type.HEADING_3) : getTypography(Type.HEADING_4)}
    }
  }

  ${Description}{
    ${({vertical}) => !vertical ? `display: none;` : `display: -webkit-box;`}

    ${getMediaQuery(ScreenSize.LARGE)}{
      display: -webkit-box;
    }
  }

  ${Content}{
    ${({vertical}) => vertical ? 
    `
      margin-top: ${getSpacerAsPixels()};
    `
    : 
    `
      margin-inline-start: ${getGutterAsPixels(ScreenSize.SMALL,{multiplier:0.5})};

      ${getMediaQuery(ScreenSize.MEDIUM)}{
        margin-inline-start: ${getGutterAsPixels(ScreenSize.MEDIUM,{multiplier:0.5})};
      }

      ${getMediaQuery(ScreenSize.LARGE)}{
        margin-inline-start: ${getGutterAsPixels(ScreenSize.LARGE,{multiplier:0.5})};
      }

      ${getMediaQuery(ScreenSize.XLARGE)}{
        margin-inline-start: ${getGutterAsPixels(ScreenSize.XLARGE,{multiplier:0.5})};
      }

      ${getMediaQuery(ScreenSize.MAX)}{
        margin-inline-start: ${getGutterAsPixels(ScreenSize.MAX,{multiplier:0.5})};
      }
    `}
  }

  ${Thumbnail}{
    ${({vertical}) => vertical ? ``
    : 
    `
      margin-inline-end: ${getGutterAsPixels(ScreenSize.SMALL,{multiplier:0.5})};

      ${getMediaQuery(ScreenSize.MEDIUM)}{
        margin-inline-end: ${getGutterAsPixels(ScreenSize.MEDIUM,{multiplier:0.5})};
      }

      ${getMediaQuery(ScreenSize.LARGE)}{
        margin-inline-end: ${getGutterAsPixels(ScreenSize.LARGE,{multiplier:0.5})};
      }

      ${getMediaQuery(ScreenSize.XLARGE)}{
        margin-inline-end: ${getGutterAsPixels(ScreenSize.XLARGE,{multiplier:0.5})};
      }

      ${getMediaQuery(ScreenSize.MAX)}{
        margin-inline-end: ${getGutterAsPixels(ScreenSize.MAX,{multiplier:0.5})};
      }
    `}
  }


`;