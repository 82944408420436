import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { useDictionary } from '../../../context/dictionary';

import { Action, ArrowIcon, Container, Heading, Publication } from './card.styles';
import { StoryCardProps } from './card.types';

export const StoryCard = ({heading,publication,link}:StoryCardProps) => {

  const action = useDictionary("actionStory");

  let image = null;

  if(publication && publication.logo){
    if(publication.logo.gatsbyImageData){
      image = publication.logo.gatsbyImageData;
    }
    else if(publication.logo.responsiveImage){
      image = {
        images:{
          fallback: {
            srcSet:publication.logo.responsiveImage.srcSet,
            src:publication.logo.responsiveImage.src,
            sizes:publication.logo.responsiveImage.sizes,
          }
        },
        layout: "fixed",
        width: publication.logo.responsiveImage.width,
        height: publication.logo.responsiveImage.height,
      }
    }
  }

  return (
    <Container>
      <Publication href={publication.url} target="_blank">
        {image && <GatsbyImage image={image} alt={publication.logo.alt || heading} />}
      </Publication>
      <Heading href={link} target="_blank">{heading}</Heading>
      <Action href={link} target="_blank">{action}
      <ArrowIcon width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.2998 9.48309L9.63284 2.15006" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/>
        <path d="M3.2998 1.1496H10.6328V8.48263" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/>
      </ArrowIcon>
    </Action>
    </Container>
  )
};